/**
 * Manage global libraries like jQuery or THREE from the package.json file
 */

// Import Packages
import 'svgxuse'
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import inlineSVG from 'inline-svg'
import ScrollMagic from 'scrollmagic'

// Import modules
import Video from './modules/video'
import Sticky from './modules/sticky'
import Navigation from './modules/navigation'
import ElementScroll from './modules/element-scroll'

const App = {
    initSticky() {
        new Sticky({
            elem: '.site-header',
        })
    },
    initSecondNav() {
        new Navigation({
            elem: '#secondary-navigation',
            toggleBtn: '#secondary-button',
            navMenu: '#secondary-navigation-menu'
        })
    },
    initMobileNav() {
        new Navigation({
            elem: '#mobile-navigation',
            openBtn: '#mobile-button-open',
            closeBtn: '#mobile-button-close',
            navMenu: '#mobile-navigation-menu'
        })
    },
    initVideoBanner() {
        new Video({
            elem: '.banner-video',
            playPause: '#play-pause',
            sound: '#sound'
        })
    },
    initInlineSVG() {
        inlineSVG.init({
            svgSelector: 'img.inline-svg'
        })
    },
    initAnimation() {
        const animate = document.querySelectorAll('.animate')

        if (animate.length > 0) {
            const controller = new ScrollMagic.Controller()

            animate.forEach((elem) => {
                new ScrollMagic.Scene({
                    triggerElement: elem,
                    triggerHook: 0.75
                })
                .setClassToggle(elem, 'show')
                .reverse(false)
                .addTo(controller)
            })

        }
    },
    initFormLabel() {

        function focusEvent (e) {
            this.parentElement.parentElement.classList.add('active');
        }

        function blurEvent (e) {
            this.parentElement.parentElement.classList.remove('active');
        }

        let inputs = document.querySelectorAll('.ginput_container textarea,.ginput_container input')
        for (let input of inputs) {
            input.addEventListener('focus', focusEvent)
            //input.addEventListener('blur', blurEvent)
        }

        const isPasswordPresent = document.querySelector('.post-password-form');
        const documentBody = document.querySelector('body');

        if(isPasswordPresent) {
            documentBody.classList.add('password-protected');
        } else {
            documentBody.classList.remove('password-protected');
        }
    },
    init() {
        this.initSticky()
        this.initSecondNav()
        this.initMobileNav()
        this.initVideoBanner()
        this.initInlineSVG()
        this.initAnimation()
        this.initFormLabel()
    }
}

window.addEventListener('DOMContentLoaded', () => App.init())