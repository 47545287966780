import ScrollMagic from 'scrollmagic'

class ElementScroll {
    constructor(props) {
        this.props = props
        this.init()
    }

    init() {
    }
}

export default ElementScroll